var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.networks
    ? _c(
        "div",
        { staticClass: "glances-network-interfaces-wrapper" },
        _vm._l(_vm.networks, function (network) {
          return _c(
            "div",
            { key: network.name, staticClass: "interface-row" },
            [
              _c("div", { staticClass: "network-info" }, [
                _c("p", { staticClass: "network-name" }, [
                  _vm._v(_vm._s(network.name)),
                ]),
                _c("p", { staticClass: "network-speed" }, [
                  _vm._v(_vm._s(_vm._f("formatSpeed")(network.speed))),
                ]),
                _c("p", { class: `network-online ${network.online}` }, [
                  _vm._v(" " + _vm._s(network.online) + " "),
                ]),
              ]),
              network.online === "up"
                ? _c("div", { staticClass: "current" }, [
                    _c("span", { staticClass: "upload" }, [
                      _vm._v(" ↑ "),
                      _c("span", { staticClass: "val" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDataSize")(network.currentUpload)
                          )
                        ),
                      ]),
                    ]),
                    _c("span", { staticClass: "separator" }, [_vm._v("|")]),
                    _c("span", { staticClass: "download" }, [
                      _vm._v(" ↓ "),
                      _c("span", { staticClass: "val" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDataSize")(network.currentDownload)
                          )
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "total" }, [
                _c("b", { staticClass: "lbl" }, [_vm._v("Total")]),
                _vm._v(" Up "),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm._f("formatDataSize")(network.totalUpload))),
                ]),
                _c("span", { staticClass: "separator" }, [_vm._v("|")]),
                _vm._v(" Down "),
                _c("span", { staticClass: "val" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatDataSize")(network.totalDownload))
                  ),
                ]),
              ]),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }